
import { Get, Patch, Post, Delete, Put } from 'utils/axios';
import { localStorageUtil} from 'utils/util';
import { baseURL, trackBaseURL} from 'config/config.js';

/** 
 * @desc 获取剧集列表
 * @param {object} param 
 */
export function reqGetResourcePackingList(params) {
  let url = `/api/chapter/${params.typeId}/chapters`;
  return Get(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
  return new Promise((resolve, reject) => {
    let obj = {
      "code": 0,
      "message": "success",
      "serverTimestamp": 1588230824271,
      "data": {
        "pageNum": 1,
        "pageSize": 30,
        "totalCount": 19,
        "datas": [
          {
            "coverUrl": "http://mvr-resource.viewshare.net/group1/M00/00/07/wKgAoGBYJZeADvXOAACQnSA8Iuo464.jpg",
            "resourceId": 2565,
            "read": true,
            "createdBy": 2,
            "videoUrl": "http://mvr-resource.viewshare.net/group1/M00/00/07/wKgAoGBYJYWAWFH8AlWsRb4Kr-c149.mp4",
            "resourceFileUrl": "/material/resourceAuthentication/2565/莎拉与乖乖鸭中文-S1_17.vs4",
            "resourceTitle": "快乐的网球赛",
            "description": "蒂格是一个三岁的小女孩，她和同龄孩子一样，有很多需要解决的麻烦，比如总是挑剔，不喜欢看医生……伍迪是她的玩具蜘蛛，也是她的好朋友，伍迪经常帮助蒂格纠正一些错误，小朋友可以从蒂格身上找到自己的有影子，从而学会正确处理生活中的麻烦事儿。",
            "hasCollected": false,
            "keyword": [
              "动作",
              "运动",
              "体育游戏",
              "分工",
              "困难",
              "解决问题"
            ],
            "type": 0,
            "duration": 123322,
            "isTrailer": true
          },
          {
            "coverUrl": "http://mvr-resource.viewshare.net/group1/M00/00/07/wKgAoGBYbQyADg41AACO3lOtr9c844.jpg",
            "resourceId": 4478,
            "read": true,
            "createdBy": 2,
            "videoUrl": "http://mvr-resource.viewshare.net/group1/M00/00/07/wKgAoGBYbQyABmJ_AyWs4f0xYiw097.mp4",
            "resourceFileUrl": "/material/resourceAuthentication/4478/Charlie & Lola-Series 02-Set 02-013.vs4",
            "resourceTitle": "我真的真的很专心（英文版）",
            "description": "",
            "hasCollected": true,
            "keyword": [
              "体育",
              "运动",
              "爱好"
            ],
            "type": 0,
            "isTrailer": true
          },
          {
            "coverUrl": "http://192.168.0.158/material/莎拉與乖乖鴨-S1_14_m.jpg",
            "resourceId": 4549,
            "read": true,
            "createdBy": 2,
            "videoUrl": "https://mini.xiaoshanyun.com/mini/resourceAuthentication/307524.mp4",
            "resourceTitle": "快乐的网球赛（英文版）",
            "description": "",
            "hasCollected": false,
            "keyword": [
              "动作",
              "运动",
              "体育游戏",
              "分工",
              "困难",
              "解决问题"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/扔球游戏_m.jpg",
            "resourceId": 4823,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/4823/扔球游戏.vs4",
            "resourceTitle": "扔球游戏",
            "description": "",
            "hasCollected": true,
            "keyword": [
              "雨伞",
              "游戏",
              "扔球"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/BabyJake-S2_24_m.jpg",
            "resourceId": 3968,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3968/BabyJake-S2_24.vs4",
            "resourceTitle": "跑跑跑（英文版）",
            "description": "Baby Jake继续与pengy Quin在雪地冒险。这两个朋友假装在雪中奔驰。那然后杰克给pengy Quin一个回骑的飞奔。",
            "hasCollected": true,
            "keyword": [
              "动物",
              "北极熊",
              "奔跑",
              "模仿",
              "创造"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/Baby Jake 16_m.jpg",
            "resourceId": 2983,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/2983/Baby Jake 16.vs4",
            "resourceTitle": "跳啊跳",
            "description": "Baby Jake和仓鼠nauts去太空冒险，并享受跳跃。",
            "hasCollected": false,
            "keyword": [
              "体育游戏",
              "双脚连续向前跳",
              "协调",
              "灵活",
              "感受",
              "自我保护"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/BabyJake-S2_05_m.jpg",
            "resourceId": 3950,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3950/BabyJake-S2_05.vs4",
            "resourceTitle": "摇啊摇（英文版）",
            "description": "Baby Jake去菜地冒险，发现西红柿摇晃他们的葡萄树藤。",
            "hasCollected": true,
            "keyword": [
              "摇摆",
              "番茄藤蔓",
              "身体运动",
              "思维",
              "观察"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/BBCW_BabyJake_16_16x9_25_CN_ProRes_422_HD_m.jpg",
            "resourceId": 3935,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3935/BBCW_BabyJake_16_16x9_25_CN_ProRes_422_HD.vs4",
            "resourceTitle": "跳啊跳（英文版）",
            "description": "Baby Jake和仓鼠nauts去太空冒险，并享受跳跃。",
            "hasCollected": false,
            "keyword": [
              "体育游戏",
              "双脚连续向前跳",
              "协调",
              "灵活",
              "感受",
              "自我保护"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/BBCW_BobinogsSeries3_6_16x9_25_HK_ProRes_422_SD_m.jpg",
            "resourceId": 3617,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3617/BBCW_BobinogsSeries3_6_16x9_25_HK_ProRes_422_SD.vs4",
            "resourceTitle": "各种运动（英文版）",
            "description": "",
            "hasCollected": false,
            "keyword": [
              "体育运动",
              "项目",
              "人数",
              "快乐",
              "增强体质"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/51_m.jpg",
            "resourceId": 3504,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3504/51.vs4",
            "resourceTitle": "平衡",
            "description": "",
            "hasCollected": false,
            "keyword": [
              "动作",
              "动物",
              "游戏",
              "体育"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/Bobinogs波尼奥乐队 Series 3-EP06_m.jpg",
            "resourceId": 3258,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3258/Bobinogs波尼奥乐队 Series 3-EP06.vs4",
            "resourceTitle": "各种运动",
            "description": "",
            "hasCollected": false,
            "keyword": [
              "体育运动",
              "项目",
              "人数",
              "快乐",
              "增强体质"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/Harry And Toto 16_m.jpg",
            "resourceId": 3165,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3165/Harry And Toto 16.vs4",
            "resourceTitle": "输与赢",
            "description": "",
            "hasCollected": true,
            "keyword": [
              "比赛",
              "手眼协调能力",
              "平衡能力"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/BabyJake50_m.jpg",
            "resourceId": 3017,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/3017/BabyJake50.vs4",
            "resourceTitle": "跑跑跑",
            "description": "Baby Jake继续与pengy Quin在雪地冒险。这两个朋友假装在雪中奔驰。那然后杰克给pengy Quin一个回骑的飞奔。",
            "hasCollected": false,
            "keyword": [
              "动物",
              "北极熊",
              "奔跑",
              "模仿",
              "创造"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/BabyJake31_m.jpg",
            "resourceId": 2998,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/2998/BabyJake31.vs4",
            "resourceTitle": "摇啊摇",
            "description": "Baby Jake去菜地冒险，发现西红柿摇晃他们的葡萄树藤。",
            "hasCollected": true,
            "keyword": [
              "摇摆",
              "番茄藤蔓",
              "身体运动",
              "思维",
              "观察"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/Dinopaws 26_m.jpg",
            "resourceId": 2832,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/2832/Dinopaws 26.vs4",
            "resourceTitle": "平衡木",
            "description": "",
            "hasCollected": true,
            "keyword": [
              "平衡能力"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/Charlie & Lola Ep52_m.jpg",
            "resourceId": 2681,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/2681/Charlie & Lola Ep52.vs4",
            "resourceTitle": "字都太难了",
            "description": "",
            "hasCollected": false,
            "keyword": [
              "克服困难",
              "尝试任务",
              "自信心"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/HarryAndToto_ep16_mandarin_m.jpg",
            "resourceId": 4206,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/4206/HarryAndToto_ep16_mandarin.vs4",
            "resourceTitle": "输与赢（英文版）",
            "description": "",
            "hasCollected": true,
            "keyword": [
              "比赛",
              "手眼协调能力",
              "平衡能力"
            ],
            "type": 0
          },
          {
            "coverUrl": "http://192.168.0.158/material/BBCW_ShowMeShowMeSeries02(WwVersion)_11_16x9_25_CN_ProRes_422_SD_m.jpg",
            "resourceId": 4016,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/4016/BBCW_ShowMeShowMeSeries02(WwVersion)_11_16x9_25_CN_ProRes_422_SD.vs4",
            "resourceTitle": "平衡（英文版）",
            "description": "",
            "hasCollected": true,
            "keyword": [
              "动作",
              "动物",
              "游戏",
              "体育"
            ],
            "type": 0
          },

          {
            "coverUrl": "http://192.168.0.158/material/Dinopaws_26_m.jpg",
            "resourceId": 4411,
            "read": true,
            "createdBy": 2,
            "resourceFileUrl": "/material/resourceAuthentication/4411/Dinopaws_26.vs4",
            "videoUrl": "https://mini.xiaoshanyun.com/mini/resourceAuthentication/307946.mp4",
            "resourceTitle": "平衡木（英文版）",
            "description": "",
            "hasCollected": false,
            "keyword": [
              "平衡能力"
            ],
            "type": 0
          },
        ]
      }
    }
    setTimeout(() => {
      resolve(obj)
    }, 200);
  });
}
/** 
 * @desc 获取资源列表
 * @param {object} param 
 */
export function reqGetResourceList(params) {
  let url = `/api/resource/${params.typeId}/resources/${params.resourcePackingId}`;
  return Get(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}


/** 
 * @desc 查看我的收藏
 * @param {object} param 
 */
export function reqGetCollectList(params) {
  let url = '/api/v1/personal/collect';
  return Get(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}


/** 
 * @desc 查看浏览记录
 * @param {object} param 
 */
export function reqGetHistoryPlay(params) {
  let url = '/api/v1/personal/history';
  return Get(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 批量同步历史记录
 * @param {object} param 
 */
export function reqSyncHistory(params) {
  let url = '/api/v1/personal/history';
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 获取播放资源地址
 * @param {object} param 
 */
export function reqGetVideo(params) {
  let url = `/family/resourceAuthentication/${params.resourceId}`;
  return Get(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
  return new Promise((resolve, reject) => {
    let obj = {
      "code": 0,
      "message": "success",
      "serverTimestamp": 1588230824271,
      "data": "http://mvr-resource.viewshare.net/group1/M00/00/07/wKgAoGBYbQyABmJ_AyWs4f0xYiw097.mp4"
    }
    setTimeout(() => {
      resolve(obj)
    }, 500);
  });
}
/** 
 * /generate/txt2Img
 * 生成图片重新生成
 */
export function getImg(params) {
  let url = '/generate/txt2Img';
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
/** 
 * `/generate/${taskId}`
 * 图片生成进度
 */
export function getProgress(params) {
  let url = `/generate/${params.taskId}`;
  return Get(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
/** 
 * /painting/{taskId}
 * 删除绘画记录
 */
export function deletePainting(params) {
  let url = `/painting/${params.taskId}`;
  return Delete(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
/** 
 * /painting
 * 绘画历史记录
 * 
 */
export function reqPainting(params) {
  let url = '/painting';
  return Get(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
/** 
 * /painting/{taskId}/down/{id}
 * 图片下载
 */
function createObjectURL(object) { return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object)};
export function downPic(params) {
  let url = `/painting/${params.taskId}/down/${params.paintingRecordId}`;
  // let url = `/painting/${params.taskId}/down/1234`;
  url = baseURL + url
  let accountInfo = localStorageUtil.getItem('accountInfo')
  console.log(accountInfo,'----------');
  let access_token = accountInfo.access_token
  let token_type = accountInfo.token_type
  var xhr = new XMLHttpRequest();
  var formData = new FormData();
  xhr.open('get',url);  //url填写后台的接口地址，如果是post，在formData append参数（参考原文地址）
  xhr.setRequestHeader("Authorization", token_type + ' '+access_token);
  xhr.responseType = 'blob';
  xhr.onload = function (e) {
      if (this.status == 200) {
          console.log(this.response)
          // var blob = this.response;
          var res = this.response;
          var blob = new Blob([res], {type: "application/octet-stream"});
          var filename = params.fileName;
          if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, filename);
          } else {
            var a = document.createElement('a');
           var url = createObjectURL(blob);
           a.href = url;
           a.download = params.taskId + ".png";
           document.body.appendChild(a);
           a.click();
           window.URL.revokeObjectURL(url);
          }
          
      }
  };
  xhr.send(formData);

  // return Get(url, params)
  //   .then(response => {
  //     console.log(response);
  //     return Promise.resolve(response)
  //   })
  //   .catch(error => {
  //     return Promise.reject(error)
  //   })

}
/** 
 * 修改个人信息(昵称)
 * /user
 */
export function upDataUser(params) {
  let url = `user`;
  return Put(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}


import axios from 'axios'
import { Get, Patch, Post, Put, Delete } from 'utils/axios';
import { localStorageUtil } from "utils/util";
import { baseURL, auth } from 'config/config';

/** 
* 获取token
* @param {Object} param: 参数对象
*/
export function reqLogin(param) {
  const url = 'oauth/token'
  const method = 'post'
  const params = {
    username: param ? param.username : 'vs123456',
    password: param ? param.password : 'MTIzNDU2',
    grant_type: 'password'
  }

  return axios.request({ baseURL, params, method, url, auth })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error.response)
    })
  return new Promise((resolve, reject) => {
    let obj = {
      "status": 200,
      "code": 0,
      "message": "success",
      "serverTimestamp": 1594274830482,
      "data": {
        "access_token":"4e184767-4199-488c-9544-a262f129eba3",
        "token_type":"bearer",
        "refresh_token":"9f8125bb-f673-461e-b5bf-a17e9f405673",
        "expires_in":35999,
        "scope":"read write",
        "privileges":{
    
        },
        "userType":2,
        "customerId":68,
        "schoolId":0,
      }
    };
    setTimeout(() => {
      resolve(obj);
    }, 300);
  });
}


/** 
 * 退出
 * @param {object} param: 参数对象
*/
export function reqLogout() {
  const url = '/user/exit'
  return Post(url)
  console.log(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
  return new Promise((resolve, reject) => {
    let obj = {
      "code": 0,
      "message": "账号安全退出",
      "serverTimestamp": 1594274830482,
      "data": null,
    };
    setTimeout(() => {
      resolve(obj);
    }, 300);
  });
}


/** 
 * @desc 修改年龄段
 * @param {object} param 
 */
export function reqUpdateAgeGroup(params) {
  let url = '/api/v1/personal/grade';
  return Put(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 校验当前手机号是否注册
 * @param {object} param  1--注册 2--找回密码
 */
export function reqAccountRepeat(params) {
  let url = `/user/${params.type}/checkPhone`;
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 发送短信验证码
 * @param {object} param 
 */
export function reqSendCode(params) {
  let url = `/user/${params.type}/sendMessage`;
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}


/** 
 * @desc 校验验证码
 * @param {object} param 
 */
export function reqRegisterCode(params) {
  let url = `/user/${params.type}/checkCode`;
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 1--注册 2--找回密码
 * @param {object} param 
 */
export function reqRegisterOrForgotPwd(params) {
  let url = `/user/${params.type}/registerOrForgotPassword`;
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 查询基本资料
 * @param {object} param 
 */
export function reqGetInformation() {
  let url = `/user`;
  return Get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 修改基本资料
 * @param {object} param 
 */
export function reqUpdateInformation(params) {
  let url = '/user';
  return Put(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/** 
 * @desc 查看宝宝
 * @param {object} param 
 */
// export function reqGetBabyList(params) {
//   let url = '/api/v1/personal/babies';
//   return Get(url, params)
//     .then(response => {
//       return Promise.resolve(response)
//     })
//     .catch(error => {
//       return Promise.reject(error)
//     })
//   return new Promise((resolve, reject) => {
//     let obj = {
//       "code":0,
//       "message":"success",
//       "serverTimestamp":1617001411076,
//       "data":[
//         {
//           "studentId": 1,
//           "studentName": 'Lucy',
//           "studentBirthday": "2014-08-03",
//           "class_name": "大1班",
//           "orgId": 1078,
//           "orgName": "金色向日葵幼儿园",
//         },
//         // {
//         //   "studentId": 3,
//         //   "studentName": '爱宝宝',
//         //   "studentBirthday": "2016-03-31",
//         //   "class_name": "good girl(大1班)",
//         //   "orgId": 1079,
//         //   "orgName": "宝贝家",
//         // },
//         // {
//         //   "studentId": 2,
//         //   "studentName": '胖丫',
//         //   "studentBirthday": "2018-06-12",
//         //   "class_name": "good boy(大1班)",
//         //   "orgId": 1078,
//         //   "orgName": "金色向日葵幼儿园",
//         // },
//         // {
//         //   "studentId": 4,
//         //   "studentName": '小魔仙',
//         //   "studentBirthday": "2017-11-31",
//         //   "class_name": "good girl(大1班)",
//         //   "orgId": 1079,
//         //   "orgName": "宝贝家",
//         // },
//       ]
//     };
//     setTimeout(() => {
//       resolve(obj);
//     }, 300);
//   });
// }

/** 
 * @desc 编辑 添加 宝宝
 * @param {object} param 
 */
// export function reqUpdateBaby(params, type = 'add') {
//   let url = type === 'add' ? '/api/v1/personal/baby' : '/api/v1/personal/baby';
//   if(type === 'add') {
//     return Post(url, params)
//       .then(response => {
//         return Promise.resolve(response)
//       })
//       .catch(error => {
//         return Promise.reject(error)
//       })
//   }else {
//     return Put(url, params)
//       .then(response => {
//         return Promise.resolve(response)
//       })
//       .catch(error => {
//         return Promise.reject(error)
//       })
//   }
  
// }


/** 
 * @desc 修改个人密码
 * @param {object} param 
 */
export function reqUpdatePassword(params) {
  let url = '/user/modifyPassword';
  return Post(url, params)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

/**
 * 网络请求相关配置
 */

let NODE_ENV = process.env.NODE_ENV
let baseURL = ''
let trackBaseURL = ''
let FeVersion = "44b68116a6dc2623c3da4698c0183e045b73c013"
let versionMessage = "44b6811 - renning, Mon Nov 27 17:26:44 2023 +0800:修改历史记录选择状态"  
let _ENV = "production"
let productType = "@PRODUCT.TYPE@"

// 生产环境
if (NODE_ENV === 'production') {
  baseURL = 'https://ai-server.viewshare.cn'
  trackBaseURL = 'https://track-server.xiaoshanyun.com'
}

// 开发环境
if (NODE_ENV === 'development') {
  baseURL = 'https://ai-server.viewshare.cn'
  trackBaseURL = 'https://track-server.xiaoshanyun.com'
}

const auth = {
  username: 'viewshare',
  password: '4kOiztf8EQEJt4HTCpkBPrsFO4oz0YSG'
}

export {
  baseURL,
  trackBaseURL,
  auth,
  versionMessage,
  _ENV,
  FeVersion,
  productType
}
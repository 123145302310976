/**
 * 路由变化时的统一操作
 * **/
import router from 'router';
import store from 'store';
import { Message } from 'element-ui';
import Cookies from "js-cookie";
import { localStorageUtil, debounce, preventRepeatMessage } from "utils/util";
import { TrackEvent } from "api/common";

let cookieOpen = CookieEnable();


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + ' - 小步AI魔法画板';
  } else {
    document.title = '小步AI魔法画板';
  }
  if (!cookieOpen && to.path !== '/home-page') {
    Message.error("对不起，您的浏览器的Cookie功能被禁用，请开启");
    router.push('/home-page');
    return next();
  }
  resetParams(to, from, next);
  tokenExpired(to, from, next);
  if(false && !Cookies.get('family_token') && to.path !== '/home-page' && to.meta.needToken) {
    store.commit('LOGOUT', null);
    router.push('/home-page');
    return next();
  }
  TrackEvent(to);
  next();
});

/**
 * 路由变化时 重置一些参数
 */
function resetParams(to, from, next) {
  store.commit('setNoAuthority', false);
  store.commit('setServerError', false);
  return;
  if(to.path.indexOf('/play') === -1) {
    $('body').css({'overflow-y': 'scroll'});
  }else {
    $('body').css({'overflow-y': 'hidden'});
  }
}

/**
 * token 过期验证
 */
function tokenExpired(to, from, next) {
  let _account = localStorageUtil.getItem('accountInfo');
  let _token = Cookies.get('family_token');
  if(_account && !_token) {
    let _path = router.history.current.fullPath;
    if (_path.indexOf('/home-page') >= 0 && router.history.current.query.redirect) {
      _path = router.history.current.query.redirect;
    }
    if(_path == '/home-page') {
      _path = '';
    }
    store.commit('LOGOUT', null);
    preventRepeatMessage();
    setTimeout(() => {
      router.replace({
        path: '/home-page',
        query: {
          redirect: _path,
          reload: true
        }
      });
    }, 500)
    return next();
  }
  
}
/**
 * 获取当前浏览器的cookie是否开启
 * 
 */
function CookieEnable() {
  var result = false;
  if (navigator.cookiesEnabled) {
    return true;
  };

  document.cookie = "testcookie=yes;";

  var cookieSet = document.cookie;

  if (cookieSet.indexOf("testcookie=yes") > -1) {
    let date = new Date();
    date.setTime(date.getTime() - 1);
    document.cookie = 'testcookie=yes;expires=' + date.toGMTString();
    result = true;
  }
  document.cookie = "";
  return result;
}